// @codekit-prepend "jquery.easing-1.3.js";
// @codekit-prepend "jquery.royalslider.js";
// @codekit-prepend "jquery.rs.autoplay.js";
// @codekit-prepend "slick.js";

$(window).on('load resize', function(){
    if ($(window).width() <= 899){
        $('body').removeClass('desktop').addClass('mobile');
        
    } else {
        $('body').removeClass('mobile').addClass('desktop');
    }
});
$(document).ready(function () {
    $('ul.menu li.active').prepend('<span class="line"></span>');
    $('section.page.blog h3.title').append('<span class="square"></span>');
    $('h1').prepend('<span class="square"></span>');
    $('#site').append('<div class="overlay hidden"></div>');
    $('#site').append('<div class="tothetop hidden"></div>');
    $('table#table').wrap('<div class="table-wrapper"></div>');
    $("nav#nav .toggle").on('touch click', function(){
        $("nav#nav .navigation,.overlay").toggleClass('hidden');
        $("nav#nav .toggle").toggleClass('active');
    });
    $(".overlay").on('touch click', function(){
        $("nav#nav .navigation,.overlay").addClass('hidden');
        $("nav#nav .toggle").removeClass('active');
    });
    $('nav#nav ul.menu').find('li:has(ul)').children('a').addClass("submenu");

    //$('nav#nav ul.menu').find('li:has(ul)').children('a').removeAttr('href').addClass("submenu");

    $('nav#nav ul.menu li .plus').on('touch click', function(){
        $(this).toggleClass("active").closest('li').find('ul').toggleClass('active');
    });
    $("nav#nav .navigation ul.menu").prepend('<div class="nav-header">Menu</div>');
    $('nav#nav .navigation ul.menu li a.submenu').append('<span></span>');

    $("#site").scroll(function(){
        if ($(this).scrollTop() > 120) {
            $('nav#nav,.topbar').addClass('sticky');
            $('.tothetop').removeClass('hidden');
        } else {
            $('nav#nav,.topbar').removeClass('sticky');
            $('.tothetop').addClass('hidden');
        }
    });

    $(".tothetop").on('touch click', function(){
        $('#site').animate({scrollTop : 0},100);
	    return false;
    });

    $("section.section.leistungen .columns .column").on('mouseenter', function(){
      $("section.section.leistungen .columns .column").addClass("transparent");
      $(this).removeClass("transparent");
    });

    $("section.section.leistungen .columns").on('mouseout', function(){
      $("section.section.leistungen .columns .column").removeClass("transparent");
    });

 });

 jQuery(document).ready(function($) {
  $(".royalSlider").royalSlider({
      imageScaleMode: 'fill',
      controlNavigation: 'none',
      arrowsNav: true,
      arrowsNavAutoHide: true,
      transitionType: 'fade',
      randomizeSlides: true,
      arrowsNavHideOnTouch: true,
      loop: true,
      keyboardNavEnabled: true,
      autoPlay: {
        delay: 3500,
        enabled: true,
        pauseOnHover: true
      }
  });

    $('.slick').slick({
        accessibility: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false
          }
      }
        ]
    });

});

// document.addEventListener('DOMContentLoaded', function(){
//   $('body').append('<div id="snow"></div>');
//       var script = document.createElement('script');
//       script.src = 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js';
//       script.onload = function(){
//           particlesJS("snow", {
//               "particles": {
//                   "number": {
//                       "value": 60,
//                       "density": {
//                           "enable": true,
//                           "value_area": 800
//                       }
//                   },
//                   "color": {
//                       "value": "#ffffff"
//                   },
//                   "opacity": {
//                       "value": 0.7,
//                       "random": false,
//                       "anim": {
//                           "enable": false
//                       }
//                   },
//                   "size": {
//                       "value": 4,
//                       "random": true,
//                       "anim": {
//                           "enable": false
//                       }
//                   },
//                   "line_linked": {
//                       "enable": false
//                   },
//                   "move": {
//                       "enable": true,
//                       "speed": 5,
//                       "direction": "bottom",
//                       "random": true,
//                       "straight": false,
//                       "out_mode": "out",
//                       "bounce": false,
//                       "attract": {
//                           "enable": true,
//                           "rotateX": 300,
//                           "rotateY": 1200
//                       }
//                   }
//               },
//               "interactivity": {
//                   "events": {
//                       "onhover": {
//                           "enable": false
//                       },
//                       "onclick": {
//                           "enable": false
//                       },
//                       "resize": false
//                   }
//               },
//               "retina_detect": true
//           });
//       }
//       document.head.append(script);
//   });